import './../styles/elements/loading.scss';

function Loading({isLoading}) {
    return (
        <div className={`preloader${isLoading ? "" : " hide"} `}>
            <div className="loading">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Loading;