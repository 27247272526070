import axios from "axios";


export default function getAPIClient(BX24) {
    let headers = {}
    if (BX24 !== undefined) {
        const bx24Params = BX24.getAllParams()
        headers = {
            "X-ACCESS-TOKEN": bx24Params.AUTH_ID,
            "X-ACCESS-TOKEN-EXPIRES": bx24Params.AUTH_EXPIRES,
            "X-REFRESH-TOKEN": bx24Params.REFRESH_ID,
            "X-PORTAL-MEMBER-ID": bx24Params.MEMBER_ID,
            "X-PORTAL-DOMAIN": bx24Params.DOMAIN,
            'accept': 'application/json'
        }
    }
    return axios.create({
        baseURL: `${process.env.REACT_APP__API_URL}`,
        headers: headers
    })
}
