import '../styles/elements/inline-notification.scss'

import { ReactComponent as SuccessSVG } from '../assets/notify-success.svg';
import { ReactComponent as ErrorInfoSVG } from '../assets/notify-error-info.svg';
import {useEffect, useState} from "react";


export default function InlineNotification(
    {type, text, hideFlag=true, className = ""}
) {
    /*
    Available types:
    - error
    - info
    - success
    */

    const [usingSVG, setUsingSVG] = useState()
    useEffect(() => {
        if (type === 'error' || type === 'info') {
            setUsingSVG(<ErrorInfoSVG/>)
        }
        if (type === 'success') {
            setUsingSVG(<SuccessSVG/>)
        }
    }, [type])
    return (
        <div className={
            'inline-notification ' + `notify-${type} ` + (hideFlag ? 'hide ' : ' ') + className }>
            <div className={'logo'}>
                {usingSVG}
            </div>
            <div className={'info'}><p>{text}</p></div>
        </div>
    )
}